import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { ClimbingBoxLoader } from 'react-spinners'

import { ThemeProvider } from '@material-ui/styles'

import MuiTheme from './theme'

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints'

// Pages

import PageError404 from './example-pages/PageError404'
import PageError500 from './example-pages/PageError500'
import PageError505 from './example-pages/PageError505'
const Login = lazy(() => import('./pages/auth/Login'))
const Company = lazy(() => import('./pages/auth/Company'))
const Register = lazy(() => import('./pages/auth/Register'))
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'))
const ChangePassword = lazy(() => import('./pages/auth/ChangePassword'))
// const RecoverPassword = lazy(() => import('./pages/auth/RecoverPassword'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard1'))
const Estimate = lazy(() => import('./pages/sales1/Estimate'))
const Logout = lazy(() => import('./pages/auth/Logout'))

const Routes = () => {
  const location = useLocation()

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  }

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300)
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
                <div className='d-flex align-items-center flex-column px-4'>
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className='text-muted font-size-xl text-center pt-3'>
                  Please wait while we load the live preview examples
                  <span className='font-size-lg d-block text-dark'>
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from='/' to='/login' />
            {/* <Route path={['/Overview']}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Overview" component={Overview} />
                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route> */}
            <Route
              path={[
                '/dashboard',
                '/dashboard/top-performers',
                '/dashboard/top-performer',
                '/lead-management',
                '/all-leads',
                '/lead',
                '/prospect',
                '/quoted',
                '/quote-approved',
                '/contract-signed',
                '/build-scheduled',
                '/job-complete',
                '/archived-all-leads',
                '/archived-lead',
                '/archived-prospect',
                '/archived-quoted',
                '/archived-contract-signed',
                '/archived-build-scheduled',
                '/archived-job-complete',
                '/add-lead',
                '/edit-lead',
                '/view-lead',
                '/sales-team',
                '/add-saleteams',
                '/edit-saleteams',
                '/view-saleteams',
                '/archived-saleteams',
                '/portfolio',
                '/add-portfolio',
                '/edit-portfolio',
                '/view-portfolio',
                '/roof-type',
                '/city',
                '/insurance'
                // '/two-factor-auth',
                // '/custom-fields',
                // '/firewall',
                // '/settings',
                // '/users',
                // '/add-user',
                // '/business',
                // '/add-business',
                // '/roles',
                // '/add-role'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path='/dashboard'
                      component={lazy(() =>
                        import('./pages/dashboard/Dashboard2')
                      )}
                      exact
                    />
                    <Route
                      path='/dashboard/top-performers'
                      component={lazy(() =>
                        import('./pages/dashboard/TopPerformers')
                      )}
                      exact
                    />
                    <Route
                      path='/dashboard/top-performer/:id'
                      component={lazy(() =>
                        import('./pages/dashboard/TopPerformer')
                      )}
                      exact
                    />
                    <Route
                      path='/lead-management'
                      component={lazy(() => import('./pages/crm/Leads'))}
                    />
                    <Route
                      path='/all-leads'
                      component={lazy(() =>
                        import('./pages/leads/all-leads/index')
                      )}
                    />
                    <Route
                      path='/archived-all-leads'
                      component={lazy(() =>
                        import('./pages/leads/all-leads/Archived')
                      )}
                    />
                    <Route
                      path='/lead'
                      component={lazy(() => import('./pages/crm/Leads'))}
                    />
                    <Route
                      path='/archived-lead'
                      component={lazy(() => import('./pages/crm/ArchivedLead'))}
                    />
                    <Route
                      path='/prospect'
                      component={lazy(() => import('./pages/leads/prospect'))}
                    />
                    <Route
                      path='/archived-prospect'
                      component={lazy(() =>
                        import('./pages/leads/prospect/Archived')
                      )}
                    />
                    <Route
                      path='/quoted'
                      component={lazy(() => import('./pages/leads/quoted'))}
                    />
                    <Route
                      path='/archived-quoted'
                      component={lazy(() =>
                        import('./pages/leads/quoted/Archived')
                      )}
                    />
                    <Route
                      path='/quote-approved'
                      component={lazy(() =>
                        import('./pages/leads/quote-approved')
                      )}
                    />
                    <Route
                      path='/contract-signed'
                      component={lazy(() =>
                        import('./pages/leads/contract-signed')
                      )}
                    />
                    <Route
                      path='/archived-contract-signed'
                      component={lazy(() =>
                        import('./pages/leads/contract-signed/Archived')
                      )}
                    />
                    <Route
                      path='/build-scheduled'
                      component={lazy(() =>
                        import('./pages/leads/build-scheduled')
                      )}
                    />{' '}
                    <Route
                      path='/archived-build-scheduled'
                      component={lazy(() =>
                        import('./pages/leads/build-scheduled/Archived')
                      )}
                    />
                    <Route
                      path='/job-complete'
                      component={lazy(() =>
                        import('./pages/leads/job-complete')
                      )}
                    />
                    <Route
                      path='/archived-job-complete'
                      component={lazy(() =>
                        import('./pages/leads/job-complete/Archived')
                      )}
                    />
                    <Route
                      path='/add-lead'
                      component={lazy(() => import('./pages/crm/AddLead'))}
                    />
                    <Route
                      path='/edit-lead/:id'
                      component={lazy(() => import('./pages/crm/EditLead'))}
                    />
                    <Route
                      path='/view-lead/:id'
                      component={lazy(() => import('./pages/crm/ViewLead'))}
                    />
                    <Route
                      path='/sales-team'
                      component={lazy(() => import('./pages/sales/Sales'))}
                    />
                    <Route
                      path='/add-saleteams'
                      component={lazy(() => import('./pages/sales/AddSales'))}
                    />
                    <Route
                      path='/edit-saleteams/:id'
                      component={lazy(() => import('./pages/sales/EditSales'))}
                    />
                    <Route
                      path='/view-saleteams/:id'
                      component={lazy(() => import('./pages/sales/ViewSales'))}
                    />
                    <Route
                      path='/archived-saleteams'
                      component={lazy(() =>
                        import('./pages/sales/ArchivedSales')
                      )}
                    />
                    <Route
                      path='/portfolio'
                      component={lazy(() =>
                        import('./pages/portfolio/Portfolio')
                      )}
                    />
                    <Route
                      path='/add-portfolio'
                      component={lazy(() =>
                        import('./pages/portfolio/AddPortfolio')
                      )}
                    />
                    <Route
                      path='/edit-portfolio/:id'
                      component={lazy(() =>
                        import('./pages/portfolio/EditPortfolio')
                      )}
                    />
                    <Route
                      path='/view-portfolio/:id'
                      component={lazy(() =>
                        import('./pages/portfolio/ViewPortfolio')
                      )}
                    />
                    <Route
                      path='/city'
                      component={lazy(() => import('./pages/portfolio/Cities'))}
                    />
                    <Route
                      path='/roof-type'
                      component={lazy(() =>
                        import('./pages/portfolio/RoofMaterial')
                      )}
                    />{' '}
                    <Route
                      path='/insurance'
                      component={lazy(() =>
                        import('./pages/insurance/Insurance')
                      )}
                    />
                    {/* <Route
                      path="/activity"
                      component={lazy(() => import('./pages/crm/Activity'))}
                    />
                    <Route
                      path="/opportunity"
                      component={lazy(() => import('./pages/crm/Opportunity'))}
                    />
                    <Route
                      path="/add-opportunity"
                      component={lazy(() =>
                        import('./pages/crm/AddOpportunity')
                      )}
                    />
                    <Route
                      path="/edit-opportunity/:id"
                      component={lazy(() =>
                        import('./pages/crm/EditOpportunity')
                      )}
                    />
                    <Route
                      path="/projects"
                      component={lazy(() =>
                        import('./pages/projects/Projects')
                      )}
                    />
                    <Route
                      path="/add-projects"
                      component={lazy(() =>
                        import('./pages/projects/AddProjects')
                      )}
                    />
                    <Route
                      path="/estimate"
                      component={lazy(() =>
                        import('./pages/sales/estimate/Estimate')
                      )}
                    />
                    <Route
                      path="/invoice"
                      component={lazy(() =>
                        import('./pages/sales/invoice/Invoice')
                      )}
                    />
                    <Route
                      path="/pdf-invoice"
                      component={lazy(() =>
                        import('./pages/sales/invoice/PdfInvoice')
                      )}
                    />
                    <Route
                      path="/chart-of-account"
                      component={lazy(() =>
                        import(
                          './pages/accounts/chart-of-accounts/ChartOfAccount'
                        )
                      )}
                    />
                    <Route
                      path="/customers"
                      component={lazy(() =>
                        import('./pages/data-hub/customers/Customers')
                      )}
                    />
                    <Route
                      path="/add-customer"
                      component={lazy(() =>
                        import('./pages/data-hub/customers/AddCustomer')
                      )}
                    />
                    <Route
                      path="/vendor"
                      component={lazy(() => import('./pages/data-hub/Vendor'))}
                    />
                    <Route
                      path="/sales-product"
                      component={lazy(() =>
                        import('./pages/data-hub/SalesProduct')
                      )}
                    />
                    <Route
                      path="/purchase-product"
                      component={lazy(() =>
                        import('./pages/data-hub/PurchaseProduct')
                      )}
                    />
                    <Route
                      path="/financial-years"
                      component={lazy(() =>
                        import('./pages/data-hub/FinancialYears')
                      )}
                    />
                    <Route
                      path="/categories"
                      component={lazy(() =>
                        import('./pages/data-hub/Categories')
                      )}
                    />
                    <Route
                      path="/two-factor-auth"
                      component={lazy(() =>
                        import('./pages/settings/TwoFactorAuth')
                      )}
                    />
                    <Route
                      path="/firewall"
                      component={lazy(() =>
                        import('./pages/settings/Firewall')
                      )}
                    />
                    <Route
                      path="/settings"
                      component={lazy(() =>
                        import('./pages/settings/UserSetting')
                      )}
                    />
                    <Route
                      path="/custom-fields"
                      component={lazy(() =>
                        import('./pages/settings/CustomFields')
                      )}
                    />
                    <Route
                      path="/users"
                      component={lazy(() =>
                        import('./pages/settings/users/Users')
                      )}
                    />
                    <Route
                      path="/add-user"
                      component={lazy(() =>
                        import('./pages/settings/users/AddUsers')
                      )}
                    />
                    <Route
                      path="/business"
                      component={lazy(() =>
                        import('./pages/settings/business/Business')
                      )}
                    />
                    <Route
                      path="/add-business"
                      component={lazy(() =>
                        import('./pages/settings/business/AddBusiness')
                      )}
                    />
                    <Route
                      path="/roles"
                      component={lazy(() =>
                        import('./pages/settings/roles/Roles')
                      )}
                    />
                    <Route
                      path="/add-role"
                      component={lazy(() =>
                        import('./pages/settings/roles/AddRole')
                      )}
                    /> */}
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route path={[]}>
              <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    {' '}
                  </motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route>

            <Route
              path={[
                '/login',
                '/select-company',
                '/signup',
                '/forgot-password',
                '/change-password',
                '/PageError404',
                '/PageError500',
                '/PageError505',
                '/logout'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/login' component={Login} />
                    <Route path='/select-company' component={Company} />
                    <Route path='/signup' component={Register} />
                    <Route path='/forgot-password' component={ForgotPassword} />
                    <Route path='/change-password' component={ChangePassword} />
                    <Route path='/PageError404' component={PageError404} />
                    <Route path='/PageError500' component={PageError500} />
                    <Route path='/PageError505' component={PageError505} />
                    <Route path='/logout' component={Logout} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
